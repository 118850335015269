import React, { useState } from 'react';
import styled from 'styled-components';

export default function BlogCard({ id, title, desc, time, thumb, big, navigate }) {
    const [loadStyle, setLoadStyle] = useState({ opacity: 0, transition: "opacity .5s" });
    const onImageLoad = () => setLoadStyle({ opacity: 1, transition: "opacity .5s" });

    const goToPost = () => navigate(`/blogpost/${id}`);

    return (
        big ?
        <BigCardContainer onClick={goToPost}>
            <BigBlogThumb src={thumb} style={loadStyle} className={thumb ? null : 'displaynone'} onLoad={onImageLoad} />
            <BigBlogDetails>
                <BlogTitle>{title}</BlogTitle>
                <BlogDesc>{desc}</BlogDesc>
            </BigBlogDetails>
            <BlogTime>{time}</BlogTime>
        </BigCardContainer>
        :
        <CardContainer onClick={goToPost}>
            <BlogThumb src={thumb} style={loadStyle} className={thumb ? null : 'displaynone'} onLoad={onImageLoad} />
            <BlogDetails>
                <BlogTitle>{title}</BlogTitle>
                <BlogDesc>{desc}</BlogDesc>
            </BlogDetails>
            <BlogTime>{time}</BlogTime>
        </CardContainer>
    )
}

const BigBlogDetails = styled.div`
    display: flex;
    flex-direction: column;
    justify-content: center;

    flex-grow: 1;
    gap: 5px;
    padding: 0 15px 0 15px;
`;

const BigBlogThumb = styled.img`
    object-fit: cover;
    width: 100%;
    height: 350px;

    border-radius: 15px 15px 0 0;

    mask-image: linear-gradient(to bottom, rgba(0,0,0,1), rgba(0,0,0,0.8), rgba(0,0,0,0));
`;

const BigCardContainer = styled.div`
    background: linear-gradient(0deg, rgb(86 86 86 / 10%) 0%, rgb(110 110 110 / 26%) 100%);
    border-radius: 15px;

    display: flex;
    flex-direction: column;

    box-sizing: border-box;

    aspect-ratio: 4.75/3.25;
    width: 700px;

    &:hover {
        transform: scale(1.03);
        filter: brightness(115%);
    }

    &:active {
        transform: scale(0.97);
        filter: brightness(85%);
    }

    transition-duration: .2s;
`;

const BlogThumb = styled.img`
    object-fit: cover;
    width: 100%;
    height: 275px;

    border-radius: 15px 15px 0 0;

    mask-image: linear-gradient(to bottom, rgba(0,0,0,1), rgba(0,0,0,0.8), rgba(0,0,0,0));
`;

const BlogTime = styled.div`
    font-size: .8em;
    color: lightgray;

    margin-top: auto;
    margin-bottom: 15px;
    margin-left: 15px;
`;

const BlogDetails = styled.div`
    display: flex;
    flex-direction: column;

    gap: 2.5px;
    padding: 0 15px 0 15px;

    margin-top: 15px;
`;

const BlogDesc = styled.div`
    font-size: 1.1em;
    color: lightgray;
`;

const BlogTitle = styled.div`
    font-size: 1.9em;
`;

const CardContainer = styled.div`
    background: linear-gradient(0deg, rgb(86 86 86 / 10%) 0%, rgb(110 110 110 / 26%) 100%);
    border-radius: 15px;

    display: flex;
    flex-direction: column;

    box-sizing: border-box;

    aspect-ratio: 3/4.75;
    width: 300px;

    &:hover {
        transform: scale(1.03);
        filter: brightness(115%);
    }

    &:active {
        transform: scale(0.97);
        filter: brightness(85%);
    }

    transition-duration: .2s;
`;