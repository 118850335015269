import React from 'react'
import styled from 'styled-components';
import DynamicInfo from '../DynamicInfo';

export const Skills = () => <SkillContainer>{DynamicInfo.myInfo.skillSet.map(skill => <Skilllet>{skill}</Skilllet>)}</SkillContainer>

const SkillContainer = styled.div`
    display: flex;
    flex-wrap: wrap;
    gap: 0.5rem;
    justify-content: center;

    font-size: 1.25em;

    max-width: 75%;
`;

const Skilllet = styled.div`
    background-color: #1f1f1f;

    padding: 10px 15px 10px 15px;

    border-radius: .7em;
`;