import React from 'react';
import styled, {keyframes} from 'styled-components';
import DynamicInfo from '../DynamicInfo';

export default function NameCard({ id, eT }) {
  return (
    <PerspectiveContainer>
        <CardContainer id={id} style={{transform: `rotateX(${eT.x}deg) rotateY(${eT.y}deg)`}}>
            <ProfilePhoto src={DynamicInfo.myInfo.photoPath} />
            <TextSide>
                <NameText>{DynamicInfo.myInfo.fullName}</NameText>
                <RoleText>{DynamicInfo.myInfo.role}</RoleText>
                <DescText><a href={`mailto:${DynamicInfo.myInfo.contactInfo.mail}`}>{DynamicInfo.myInfo.contactInfo.mail}</a><br /><a href={`tel:${DynamicInfo.myInfo.contactInfo.tel}`}>{DynamicInfo.myInfo.contactInfo.tel}</a></DescText>
            </TextSide>
        </CardContainer>
    </PerspectiveContainer>
  )
}

const PerspectiveContainer = styled.div`
    perspective: 1000px;
`;

const cardAnim = keyframes`
    0% {
        transform: scale(.7);
        filter: blur(10px);
        opacity: 0;
    }

    100% {
        opacity: 1;
    }
`;

const TextSide = styled.div`
    display: flex;
    flex-direction: column;

    @media (max-width: 525px) {
        text-align: center;
    }

    transition-duration: .3s;
`;

const DescText = styled.div`
    font-size: 1.5em;
    padding-top: 10%;

    > a {
        color: white;
    }

    @media (max-width: 1099px) {
        font-size: 1.25em;
    }

    @media (max-width: 750px) {
        font-size: 1em;
    }

    @media (max-width: 525px) {
        font-size: 1.25em;
        padding-top: 20%;

        @media (max-height: 725px) {
            font-size: 1.15em;
        }
    }

    @media (min-width: 1099px) {
        @media (max-height: 705px) {
            font-size: 1.25em;
        }
    }

    transition-duration: .3s;
`;
const RoleText = styled.div`
    font-size: 2em;

    @media (max-width: 1099px) {
        font-size: 1.5em;
    }

    @media (max-width: 750px) {
        font-size: 1.25em;
    }

    @media (max-width: 525px) {
        font-size: 1.5em;

        @media (max-height: 725px) {
            font-size: 1.25em;
        }
    }

    @media (min-width: 1099px) {
        @media (max-height: 766px) {
            font-size: 1.7em;
        }

        @media (max-height: 705px) {
            font-size: 1.5em;
        }
    }

    transition-duration: .3s;
`;
const NameText = styled.div`
    font-size: 4.15em;
    font-weight: 500;

    background: -webkit-linear-gradient(right, #B6FF94, #ABF5FF);
    -webkit-background-clip: text;
    background-clip: text;
    -webkit-text-fill-color: transparent;

    @media (max-width: 1099px) {
        font-size: 3em;
    }

    @media (max-width: 750px) {
        font-size: 1.96em;
    }

    @media (max-width: 525px) {
        font-size: 2.7em;

        margin-bottom: 10px;

        @media (max-height: 725px) {
            font-size: 2.5em;
        }
    }

    @media (min-width: 1099px) {
        @media (max-height: 766px) {
            font-size: 3.4em;
        }

        @media (max-height: 705px) {
            font-size: 3em;
        }
    }

    transition-duration: .3s;
`;

const ProfilePhoto = styled.img`
    width: 270px;
    border-radius: 25px;

    @media (max-width: 1099px) {
        width: 225px;
    }

    @media (max-width: 750px) {
        width: 165px;
    }

    @media (max-width: 525px) {
        width: 225px;

        @media (max-height: 725px) {
            width: 190px;
        }
    }

    @media (min-width: 1099px) {
        @media (max-height: 766px) {
            width: 260px;
        }

        @media (max-height: 705px) {
            width: 225px;
        }
    }

    transition-duration: .3s;
`;

const ShineAnim = keyframes`
    0% {
        transform: translateX(-100%) skewX(-15deg);
    }
    50% {
        opacity: .2;
    }
	100% {
        transform: translateX(100%) skewX(-15deg);
    }
`;

const CardContainer = styled.div`
    background-color: #FFFFFF1A;
    border-radius: 25px;

    display: flex;
    flex-direction: row;
    align-items: center;

    box-sizing: border-box;
    padding: 0 35px 0 35px;
    gap: 45px;

    aspect-ratio: 7/4;
    width: 900px;

    opacity: 0;
    animation: ${cardAnim} ease 1s .8s forwards;

    // filter: drop-shadow(0 0 0.75rem #000000);
    box-shadow: 0 8px 52px 0 #00000033;
    border: 1px solid #FFFFFF1A;

    // shine
    &:after {
        content: '';

        transform: translateX(100%);

        aspect-ratio: 7/4;
        width: 900px;

        clip: rect(0px, 900px, 514px, 0px);

        position: absolute;
        top: 0;

        opacity: 0;

        z-index: 1;
        animation: ${ShineAnim} 1s ease-in-out 1s;

        background: linear-gradient(to right, rgba(255,255,255,0) 0%,rgba(255,255,255,0.8) 50%,rgba(128,186,232,0) 99%,rgba(125,185,232,0) 100%);

        @media (max-width: 1099px) {
            width: 700px;
            clip: rect(0px, 700px, 400px, 0px);
        }

        @media (max-width: 750px) {
            width: 500px;
            clip: rect(0px, 700px, 286px, 0px);
        }

        @media (max-width: 525px) {
            aspect-ratio: 4/7;
            width: 325px;
            clip: rect(0px, 325px, 569px, 0px);

            @media (max-height: 725px) {
                width: 275px;
                clip: rect(0px, 275px, 481px, 0px);
            }
        }

        @media (min-width: 1099px) {
            @media (max-height: 766px) {
                width: 800px;
                clip: rect(0px, 800px, 457px, 0px);
            }

            @media (max-height: 705px) {
                width: 700px;
                clip: rect(0px, 700px, 400px, 0px);
            }
        }

        transition-duration: .05s;
    }

    @media (max-width: 1099px) {
        width: 700px;
        gap: 32.5px;
    }

    @media (max-width: 750px) {
        width: 500px;
        gap: 20px;
    }

    @media (max-width: 525px) {
        aspect-ratio: 4/7;
        width: 325px;

        flex-direction: column;
        align-items: center;
        justify-content: center;

        padding: 0 20px 0 20px;

        @media (max-height: 725px) {
            width: 275px;
        }
    }

    @media (min-width: 1099px) {
        @media (max-height: 766px) {
            width: 800px;
        }

        @media (max-height: 705px) {
            width: 700px;
            gap: 32.5px;
        }
    }

    transition: all ease .3s,
                transform ease .05s;

    overflow: hidden;
`;