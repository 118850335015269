import React from 'react';

const LoadAnim = (props) => (
  <svg
    id="eVm3gIQvpH61"
    xmlns="http://www.w3.org/2000/svg"
    xmlnsXlink="http://www.w3.org/1999/xlink"
    viewBox="0 0 300 300"
    shapeRendering="geometricPrecision"
    textRendering="geometricPrecision"
    {...props}
  >
    <style>
      {
        "\n    \n#eVm3gIQvpH62_to {animation: eVm3gIQvpH62_to__to 1600ms linear infinite normal forwards}@keyframes eVm3gIQvpH62_to__to { 0% {transform: translate(50.420969px,254.001304px);animation-timing-function: cubic-bezier(0.645,0.045,0.355,1)} 31.25% {transform: translate(50.420969px,52.341565px);animation-timing-function: cubic-bezier(0.42,0,0.58,1)} 50% {transform: translate(50.420969px,48.341565px);animation-timing-function: cubic-bezier(0.645,0.045,0.355,1)} 81.25% {transform: translate(50.420969px,249.001304px)} 100% {transform: translate(50.420969px,254.001304px)}} #eVm3gIQvpH64_to {animation: eVm3gIQvpH64_to__to 1600ms linear infinite normal forwards}@keyframes eVm3gIQvpH64_to__to { 0% {transform: translate(50.420969px,250.667971px)} 12.5% {transform: translate(50.420969px,254.001304px);animation-timing-function: cubic-bezier(0.645,0.045,0.355,1)} 43.75% {transform: translate(50.420969px,52.341565px);animation-timing-function: cubic-bezier(0.42,0,0.58,1)} 62.5% {transform: translate(50.420969px,48.341565px);animation-timing-function: cubic-bezier(0.645,0.045,0.355,1)} 93.75% {transform: translate(50.420969px,249.001304px)} 100% {transform: translate(50.420969px,250.667971px)}} #eVm3gIQvpH66_to {animation: eVm3gIQvpH66_to__to 1600ms linear infinite normal forwards}@keyframes eVm3gIQvpH66_to__to { 0% {transform: translate(50.420969px,249.001304px)} 18.75% {transform: translate(50.420969px,254.001304px);animation-timing-function: cubic-bezier(0.645,0.045,0.355,1)} 50% {transform: translate(50.420969px,52.341565px);animation-timing-function: cubic-bezier(0.42,0,0.58,1)} 68.75% {transform: translate(50.420969px,48.341565px);animation-timing-function: cubic-bezier(0.645,0.045,0.355,1)} 100% {transform: translate(50.420969px,249.001304px)}}\n\n  "
      }
    </style>
    <g id="eVm3gIQvpH62_to" transform="translate(50.420969,254.001304)">
      <ellipse
        rx={20.069882}
        ry={20.069882}
        transform="scale(1.25,1.25) translate(0,0)"
        fill="#ddd"
        strokeWidth={0}
      />
    </g>
    <g transform="translate(99.579033 0.000013)">
      <g id="eVm3gIQvpH64_to" transform="translate(50.420969,250.667971)">
        <ellipse
          rx={20.069882}
          ry={20.069882}
          transform="scale(1.25,1.25) translate(0,0)"
          fill="#ddd"
          strokeWidth={0}
        />
      </g>
    </g>
    <g transform="translate(199.158085 0)">
      <g id="eVm3gIQvpH66_to" transform="translate(50.420969,249.001304)">
        <ellipse
          rx={20.069882}
          ry={20.069882}
          transform="scale(1.25,1.25) translate(0,0)"
          fill="#ddd"
          strokeWidth={0}
        />
      </g>
    </g>
  </svg>
);

export default LoadAnim;
