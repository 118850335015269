import axios from 'axios';

const blogEndpoint = "https://blogm.amangani.nl";
const Api = {
    blogGetPosts: () => {
        return new Promise((resolve, reject) => {
            axios.get(`${blogEndpoint}/blogposts`).then(res => {
                resolve(res.data);
            }).catch(err => {
                reject(err);
            });
        });
    },
    blogGetPost: pid => {
        return new Promise((resolve, reject) => {
            axios.get(`${blogEndpoint}/blogpost/${pid}`).then(res => {
                resolve(res.data);
            }).catch(err => {
                reject(err);
            });
        });
    },
    getFullImagePath: iid => `${blogEndpoint}/image/${iid}`
}

export default Api;