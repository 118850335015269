import React from 'react';
import styled, {keyframes} from 'styled-components';
import { Route, Routes } from 'react-router-dom';

import Contact from './pages/Contact';
import Home from './pages/Home';
import Blog from './pages/Blog';
import Blogpost from './pages/Blogpost';

export default function App() {
    return (
        <AllComponents>
            <Routes>
                <Route path="/" element={<Home />} />
                <Route path="/contact" element={<Contact />} />
                <Route path="/blog" element={<Blog />} />
                <Route path="/blogpost/:pid" element={<Blogpost />} />
            </Routes>
        </AllComponents>
    )
}

const FadeInAnim = keyframes`
    0% {
        opacity: 0;
    }
`;

const AllComponents = styled.div`
    animation-name: ${FadeInAnim};
    animation-duration: .7s;
    animation-iteration-count: 1;
`;