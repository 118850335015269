import React, { useState, useEffect } from 'react';
import styled from 'styled-components';

import { useParams, useNavigate } from 'react-router-dom';

import Navbar from '../components/Navbar';
import LoadAnim from '../animation/LoadAnim';
import TechnologyFooter from '../components/TechnologyFooter';
import BlogCard from '../components/BlogCard';

import Api from '../Api';

export default function Blogpost() {
    const { pid } = useParams();

    const [isLoaded, setIsLoaded] = useState(false);
    const [blogpost, setBlogpost] = useState({});
    const [blogposts, setBlogposts] = useState([]);

    const navigator = useNavigate();

    useEffect(() => {
        Api.blogGetPost(pid).then(data => {
            if (!data.bad) {
                setBlogpost(data.post);

                Api.blogGetPosts().then(data => {
                    if (!data.bad) {
                        setBlogposts(data.posts);
                        setIsLoaded(true);
                    }
                });
            }
        });
    }, [pid]);

    

    const getReadingTime = (text) => {
        const wordsPerMinute = 225;
        const words = text.trim().split(/\s+/).length;

        return Math.ceil(words / wordsPerMinute);
    }

    const [loadStyle, setLoadStyle] = useState({ opacity: 0, transition: "opacity .5s" });
    const onImageLoad = () => setLoadStyle({ opacity: 1, transition: "opacity .5s" });

    return (
        <MainContainer>
            <Navbar />
            {
                isLoaded ?
                <>
                    <PostContainer>
                        <PostDeets>{new Date(blogpost.timestamp).toLocaleString('en-NL')} • {`${getReadingTime(blogpost.body)} minute read`}</PostDeets>
                        <PostTitle>{blogpost.title}</PostTitle>
                        <PostDesc>{blogpost.description}</PostDesc>

                        <PostThumbnail style={loadStyle} src={Api.getFullImagePath(blogpost.thumbnail)} onLoad={onImageLoad} />

                        <PostBody dangerouslySetInnerHTML={{ __html: blogpost.body }} />
                    </PostContainer>
                    {
                        blogposts.filter(post => post.blogid !== blogpost.blogid).slice(0, 3).length !== 0 ? <>
                            <OtherPostsTitle>Other posts:</OtherPostsTitle>
                            <OtherPostsRow>
                                {
                                    blogposts.filter(post => post.blogid !== blogpost.blogid).slice(0, 3).map(post =>
                                        <BlogCard
                                            id={post.blogid}
                                            title={post.title}
                                            desc={post.description}
                                            time={new Date(post.timestamp).toLocaleString('en-NL')}
                                            thumb={post.thumbnail ? Api.getFullImagePath(post.thumbnail) : null}
                                            navigate={navigator}
                                        />
                                    )
                                }
                            </OtherPostsRow>
                        </> : null
                    }
                </>
                : <LoadAnimOuter><LoadingAnimation /></LoadAnimOuter>
            }
            <TechnologyFooter />
        </MainContainer>
    )
}

const OtherPostsRow = styled.div`
    display: flex;
    flex-wrap: wrap;
    gap: 10px;

    align-self: center;

    margin-bottom: 30px;
`;

const OtherPostsTitle = styled.div`
    margin-top: 25px;
    margin-bottom: 25px;

    font-size: 2em;
    text-align: center;
`;

const PostBody = styled.div`
    font-size: 1.25em;

    display: flex;
`;

const PostDeets = styled.div`
    background: #FFFFFF08;
    border-radius: 5px;
    border: 1px solid #FFFFFF1A;

    padding: 5px;
`;

const PostDesc = styled.div`
    font-size: 1.25em;
    color: lightgray;
`;

const PostTitle = styled.div`
    margin-top: 5px;

    font-size: 2.5em;
    font-weight: 400;
`;

const PostThumbnail = styled.img`
    object-fit: cover;
    height: 300px;

    border-radius: 5px;

    margin-top: 15px;
    margin-bottom: 15px;
`;

const PostContainer = styled.div`
    margin-bottom: 10px;
    margin-top: 25px;
    padding: 25px;
    width: 850px;

    display: flex;
    flex-direction: column;

    align-self: center;

    background: linear-gradient(0deg, rgb(86 86 86 / 10%) 0%, rgb(110 110 110 / 26%) 100%);
    border-radius: 15px;

    box-shadow: 0 8px 52px 0 #00000033;
    border: 1px solid #FFFFFF1A;

    @media (max-width: 940px) {
        width: 500px;
    }

    @media (max-width: 575px) {
        width: 100%;
        min-height: calc(100vh - 101px);
        box-sizing: border-box;

        margin: 0;
        
        border: none;
        border-radius: 0;
        box-shadow: none;

        border-bottom: 1px solid #FFFFFF1A;
    }
`;

const FlexFullCentered = styled.div`
    width: 100%;

    display: flex;
    justify-content: center;
`;

const LoadAnimOuter = styled(FlexFullCentered)`
    align-items: center;

    height: 75vh;
`;

const LoadingAnimation = styled(LoadAnim)`
    width: 75px;
`;

const MainContainer = styled.div`
    width: 100%;
    min-height: 100vh;

    background-color: #1e1e1e;

    color: white;

    display: flex;
    flex-direction: column;
`;