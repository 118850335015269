const contactInfo = {
    "github": "https://github.com/yeetmydog",
    "mail": "placeholder",
    "tel": "placeholder"
}
const skillSet = ["CSS", "HTML", "Javascript", "Typescript", "React", "NodeJS", "Cloudflare Workers", "C"];
const aboutMe = "TODO"
const fullName = "Angelo Mangani";
const role = "Full-Stack Developer";
const photoPath = "/assets/placeholder_profile.png"

const myInfo = {contactInfo, skillSet, aboutMe, fullName, role, photoPath}

const footerText = "Made from scratch with ❤️ in Amsterdam • Hosted on Cloudflare";

const DynamicInfo = {myInfo, footerText};

export default DynamicInfo;