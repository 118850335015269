import React, { useEffect, useState } from 'react'
import styled, {keyframes} from 'styled-components';

import NameCard from '../components/NameCard';
import Navbar from '../components/Navbar';
import { Skills } from '../components/Skills';
import TechnologyFooter from '../components/TechnologyFooter';

import DynamicInfo from '../DynamicInfo';

export default function Home() {
    const helloIAmSetText = "$ whoami";
    const [helloIAmText, setHelloIAmText] = useState("");
    useEffect(() => {
        const timeout = setTimeout(() => {
            setHelloIAmText(helloIAmSetText.slice(0, helloIAmText.length + 1));
        }, 100);
        return () => clearTimeout(timeout);
    }, [helloIAmText]);

    //////////////////////////////////////////////////////////////////////////////////

    const rotMultiple = 75;
    const [elTransform, setElTransform] = useState({ x: 0, y: 0 });
    useEffect(() => {
        const handleMouseMove = (event) => {
            const bounds = document.getElementById("card").getBoundingClientRect();
            setElTransform({ 
                x: -(event.clientY - bounds.y - (bounds.height / 2)) / rotMultiple,
                y: (event.clientX - bounds.x - (bounds.width / 2)) / rotMultiple
            });
        }

        window.addEventListener('mousemove', handleMouseMove);

        return () => {
            window.removeEventListener(
                'mousemove',
                handleMouseMove
            );
        };
    }, []);

    //////////////////////////////////////////////////////////////////////////////////

    return (
        <>
            <MainContainer>
                <Navbar />
                <PageContent>
                    <HelloIAm>{helloIAmText}</HelloIAm>
                    <NameCard id="card" eT={elTransform} />
                        <ScrollDownArrow>
                            <ScrollDownArrowDrawing xmlns="http://www.w3.org/2000/svg" viewBox="0 0 22 22">
                                <path
                                    d="m345.44 248.29l-194.29 194.28c-12.359 12.365-32.397 12.365-44.75 0-12.354-12.354-12.354-32.391 0-44.744l171.91-171.91-171.91-171.9c-12.354-12.359-12.354-32.394 0-44.748 12.354-12.359 32.391-12.359 44.75 0l194.29 194.28c6.177 6.18 9.262 14.271 9.262 22.366 0 8.099-3.091 16.196-9.267 22.373"
                                    transform="matrix(-.00013.03541.03541.00013 3.02 2.988)"
                                    fill="#FFFFFF"
                                />
                            </ScrollDownArrowDrawing>
                        </ScrollDownArrow>
                </PageContent>
            </MainContainer>
            <AboutContainer id="about">
                <AboutMeHeader>About Me:</AboutMeHeader>
                <hr />
                <AboutContent>
                    <AboutPersonContainer>
                        <AboutPersonHeader>cat about.txt</AboutPersonHeader>
                        <AboutPersonDesc>{DynamicInfo.myInfo.aboutMe}</AboutPersonDesc>
                    </AboutPersonContainer>
                    <SkillContainer>
                        <SkillHeader>cat skills.txt</SkillHeader>
                        <Skills />
                    </SkillContainer>
                </AboutContent>
            </AboutContainer>
            <ProjectsContainer>
                Work In Progress
            </ProjectsContainer>
            <TechnologyFooter />
        </>
    )
}



const CursorBlink = keyframes`
  100% {
    opacity: 1;
  }
  50% {
    opacity: 0;
  }
`;

const ProjectsContainer = styled.div`
    width: 100%;
    height: 500px;

    background-color: #1e1e1e;
    color: white;

    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
`;

const AboutPersonDesc = styled.div`
    inline-size: 500px;
    overflow-wrap: break-word;

    font-size: 1.25em;

    @media (max-width: 527px) {
        inline-size: 250px;
    }

    transition-duration: .3s;
`;

const AboutPersonHeader = styled.div`
    font-size: 3em;

    @media (max-width: 527px) {
        font-size: 2em;
    }

    transition-duration: .3s;
`;

const AboutPersonContainer = styled.div`
    display: flex;
    align-items: center;
    flex-direction: column;

    gap: 1rem;
`;

const SkillHeader = styled.div`
    font-size: 3em;

    @media (max-width: 527px) {
        font-size: 2em;
    }
`;

const SkillContainer = styled.div`
    display: flex;
    align-items: center;
    flex-direction: column;

    gap: 1rem;

    @media (max-width: 527px) {
        min-width: 250px;
    }

    transition-duration: .3s;
`;

const AboutContent = styled.div`
    display: grid;
    grid-template-columns: 1fr 1fr;
    grid-gap: 5rem;

    @media (max-width: 1099px) {
        display: flex;
        flex-direction: column;
        align-items: center;
        gap: 5rem;
    }

    transition-duration: .3s;
`;

const AboutMeHeader = styled.div`
    font-size: 4em;

    @media (max-width: 527px) {
        font-size: 3em;
    }
`;

const AboutContainer = styled.div`
    width: 100%;
    height: auto;

    background-color: #282828;
    color: white;

    display: flex;
    align-items: center;
    flex-direction: column;

    padding-top: 45px;
    padding-bottom: 50px;

    border-top: 1px solid #FFFFFF1A;
    border-bottom: 1px solid #FFFFFF1A;

    > hr {
        width: 70%;
        opacity: 10%;

        margin: 20px;
        margin-bottom: 30px;
    }
`;

const ScrollDownArrowAppear = keyframes`
    from { opacity: 0; }
    to { opacity: 1; }
`;

const ScrollDownArrowDrawing = styled.svg`
    opacity: 0;

    animation: ${ScrollDownArrowAppear} .5s ease 1.2s;
    animation-fill-mode: forwards;
`;

const ScrollDownArrowAnim = keyframes`
    0% {
        transform: none;
    }

    50% {
        transform: translateY(-10px);
    }
`;

const ScrollDownArrow = styled.div`
    position: absolute;
    bottom: 5%;

    width: 50px;
    height: 50px;

    animation-name: ${ScrollDownArrowAnim};
    animation-duration: 2s;
    animation-iteration-count: infinite;

    @media (max-height: 802px) { 
            bottom: 2.5%;
    }

    @media (min-width: 725px) {
        @media (max-height: 642px) {
            opacity: 0;
            bottom: -2%;
        }
    }

    @media (max-width: 725px) {
        @media (max-height: 540px) {
            opacity: 0;
            bottom: -2%;
        }
    }

    @media (max-width: 525px) {
        bottom: 2%;

        @media (max-height: 822px) { bottom: 1%; }

        @media (max-height: 807px) {
            opacity: 0;
            bottom: -2%;
        }
    }

    transition-duration: .3s;
`;

const HelloIAmAnim = keyframes`
    0% {
        transform: scale(.8);
        filter: blur(10px);
        opacity: 0;
    }
`;

const HelloIAm = styled.div`
    font-size: 2.5em;
    padding-top: 25px;
    padding-bottom: 35px;

    animation-name: ${HelloIAmAnim};
    animation-duration: 1s;
    animation-iteration-count: 1;

    @media (max-width: 525px) {
        padding-bottom: 15px;
        padding-top: 15px;
    }

    transition-duration: .3s;

    ::after {
        content: " |";
        animation: ${CursorBlink} 1s ease-in-out infinite;
    }

    user-select: none;
`;

const PageContent = styled.div`
    display: flex;

    align-items: center;
    flex-direction: column;
`;

const MainContainer = styled.div`
    width: 100%;
    height: 100vh;
    min-height: 650px;

    background-color: #1e1e1e;

    color: white;

    display: flex;
    flex-direction: column;
`;