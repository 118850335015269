import React, { useState, useEffect } from 'react';
import styled, { keyframes } from 'styled-components';

import Api from '../Api';

import { useNavigate } from 'react-router-dom';

import Navbar from '../components/Navbar';
import BlogCard from '../components/BlogCard';
import LoadAnim from '../animation/LoadAnim';
import TechnologyFooter from '../components/TechnologyFooter';

export default function Blog() {
    const [windowSize, setWindowSize] = useState([
        window.innerWidth,
        window.innerHeight,
    ]);

    useEffect(() => {
        const handleWindowResize = () => {
            setWindowSize([window.innerWidth, window.innerHeight]);
        }

        window.addEventListener('resize', handleWindowResize);

        return () => {
            window.removeEventListener('resize', handleWindowResize);
        }
    });

    const [isLoaded, setIsLoaded] = useState(false);
    const [blogposts, setBlogposts] = useState([]);

    useEffect(() => {
        Api.blogGetPosts().then(data => {
            if (!data.bad) {
                setBlogposts(data.posts);
                setIsLoaded(true);
            }
        });
    }, []);

    const navigator = useNavigate();

    return (
        <MainContainer>
            <Navbar />
            { isLoaded ?
            <FadeIn>
                <FlexFullCentered>
                    <BlogHeader>
                        Blog
                    </BlogHeader>
                </FlexFullCentered>
                <>
                    {windowSize[0] > 1145 ? 
                    <>
                        <BlogTopRow>
                            {
                                blogposts[0] ? <BlogCard big
                                    id={blogposts[0].blogid}
                                    title={blogposts[0].title}
                                    desc={blogposts[0].description}
                                    time={new Date(blogposts[0].timestamp).toLocaleString('en-NL')}
                                    thumb={blogposts[0].thumbnail ? Api.getFullImagePath(blogposts[0].thumbnail) : null}
                                    navigate={navigator}
                                /> : null
                            }
                            {
                                blogposts[1] ? <BlogCard
                                    id={blogposts[1].blogid}
                                    title={blogposts[1].title}
                                    desc={blogposts[1].description}
                                    time={new Date(blogposts[1].timestamp).toLocaleString('en-NL')}
                                    thumb={blogposts[1].thumbnail ? Api.getFullImagePath(blogposts[1].thumbnail) : null}
                                    navigate={navigator}
                                /> : null
                            }
                        </BlogTopRow>
                        <FlexFullCentered>
                            <BlogRestRow>
                                {
                                    blogposts.slice(2).map(post =>
                                        <BlogCard
                                            id={post.blogid}
                                            title={post.title}
                                            desc={post.description}
                                            time={new Date(post.timestamp).toLocaleString('en-NL')}
                                            thumb={post.thumbnail ? Api.getFullImagePath(post.thumbnail) : null}
                                            navigate={navigator}
                                        />
                                    )
                                }
                            </BlogRestRow>
                        </FlexFullCentered>
                    </>
                    :
                    <FlexFullCentered>
                        <BlogRestRow>
                            {
                                blogposts.map(post =>
                                    <BlogCard
                                        id={post.blogid}
                                        title={post.title}
                                        desc={post.description}
                                        time={new Date(post.timestamp).toLocaleString('en-NL')}
                                        thumb={post.thumbnail ? Api.getFullImagePath(post.thumbnail) : null}
                                        navigate={navigator}
                                    />
                                )
                            }
                        </BlogRestRow>
                    </FlexFullCentered>
                    }
                </>
            </FadeIn> :
            <LoadAnimOuter><LoadingAnimation /></LoadAnimOuter>
        }
        <TechnologyFooter />
        </MainContainer>
    )
}


const FadeInAnim = keyframes`
    0% {
        opacity: 0;
    }
`;

const FadeIn = styled.div`
    animation-name: ${FadeInAnim};
    animation-duration: .7s;
    animation-iteration-count: 1;
`;

const FlexFullCentered = styled.div`
    width: 100%;

    display: flex;
    justify-content: center;
`;

const LoadAnimOuter = styled(FlexFullCentered)`
    align-items: center;

    height: 75vh;
`;

const LoadingAnimation = styled(LoadAnim)`
    width: 75px;
`;

const BlogRestRow = styled.div`
    display: flex;
    flex-direction: row;
    justify-content: center;
    flex-wrap: wrap;

    gap: 30px;

    margin-bottom: 30px;

    max-width: 1540px;

    padding-left: 5%;
    padding-right: 5%;
`;

const BlogHeader = styled.div`
    font-size: 3.5em;
    text-align: center;

    width: 75%;

    padding: 25px;

    user-select: none;
`;

const BlogTopRow = styled.div`
    display: flex;
    flex-direction: row;
    justify-content: center;

    margin-bottom: 30px;

    gap: 30px;

    padding-left: 5%;
    padding-right: 5%;
`

const MainContainer = styled.div`
    width: 100%;
    min-height: 100vh;

    background-color: #1e1e1e;

    color: white;

    display: flex;
    flex-direction: column;
`;