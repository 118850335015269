import React from 'react';
import { NavLink } from 'react-router-dom';
import styled from 'styled-components';
import DynamicInfo from '../DynamicInfo';
import { HashLink } from 'react-router-hash-link';

export default function Navbar() {
  return (
    <NavbarContainer>
        <NavbarLink to="/" exact>Home</NavbarLink>
        <AnchorLink to="/#about">About</AnchorLink>
        <NavbarLink to="/blog" exact>Blog</NavbarLink>
        <NavbarLink to="/contact" exact>Contact</NavbarLink>
        <NavbarLink
            to={{ pathname: DynamicInfo.myInfo.contactInfo.github }}
            target="_blank"
            rel="noopener noreferrer"
        >Github</NavbarLink>
    </NavbarContainer>
  )
}

const AnchorLink = styled(HashLink)`
    color: #bdbdbd;

    text-decoration: none;
    font-size: 1.2em;

    margin-top: 5px;
    margin-bottom: 5px;

    user-select: none;
`;

const NavbarLink = styled(NavLink)`
    color: #bdbdbd;

    text-decoration: none;
    font-size: 1.2em;

    &.active {
        color: white
    }

    margin-top: 5px;
    margin-bottom: 5px;

    user-select: none;
`;

const NavbarContainer = styled.div`
    top: 0;
    position: sticky;

    width: 100%;
    min-height: 60px;

    background-color: #282828;
    
    box-shadow: 0 5px 8px #0000001A;

    display: flex;
    justify-content: center;
    align-items: center;

    flex-wrap: wrap;

    gap: 25px;

    @media (max-width: 440px) {
        gap: 15px;
    }

    @media (max-width: 440px) {
        gap: 15px;
    }

    z-index: 2;
`;
