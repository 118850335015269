import React from 'react';
import styled from 'styled-components';

import DynamicInfo from '../DynamicInfo';

export default function TechnologyFooter() {
    return (
        <FooterContainer>
            <FooterText>{DynamicInfo.footerText}</FooterText>
        </FooterContainer>
    )
}

const FooterText = styled.div`
    font-size: .9em;

    text-align: center;

    padding-left: 5px;
    padding-right: 5px;
`;

const FooterContainer = styled.div`
    width: 100%;
    height: 40px;

    display: flex;

    justify-content: center;
    align-items: center;

    background-color: #282828;
    color: white;

    border-top: 1px solid #FFFFFF1A;

    margin-top: auto;
`;